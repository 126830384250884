<template lang="pug">
header.FcwHero(
  :class="classes"
  :style="style"
)
  .FcwHero__contentWrapper
    h2.FcwHero__title(v-html="title")
    slot(name="content")
  .FcwHero__bottomContent
    h4.FcwHero__subtitle(
      v-show="mode !== 'blog'"
      v-html="subtitle"
    )
    .FcwHero__cta(v-if="ctaLocation")
      FcwButtonLink(
        :location="ctaLocation"
        icon
      )
        FIcon.FcwHero__ctaIcon(
          name="arrowRight"
          :stroke-width="1"
          stroke-color="neutral--light-5"
        )
</template>

<style lang="stylus">
.FcwHero
  position relative
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  min-height 100vh
  color var(--color--neutral--light-5)
  text-align center
  overflow hidden
  padding rem(32)

  &::before
    content ''
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    z-index -1
    background var(--FcwHero--gradient)

  &--backgroundImage
    background linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))

    &::before
      background-repeat no-repeat
      background-position center center
      background-size cover
      background-image var(--FcwHero--backgroundImageUrl--lg)

    +media-down('md')
      &::before
        background-image var(--FcwHero--backgroundImageUrl--md)

    +media-down('sm')
      &::before
        background-image var(--FcwHero--backgroundImageUrl--sm)

    +media-down('xs')
      &::before
        background-image var(--FcwHero--backgroundImageUrl--xs)

  &--blogHome
    min-height 80vh

    +media-down('sm')
      min-height 100vh

  &--blog
    min-height 80vh

.FcwHero__contentWrapper
  display flex
  flex-grow 1
  flex-direction column
  align-items center
  justify-content center
  width 100%
  gap rem(40)

.FcwHero__title
  max-width rem(840)
  white-space pre-line

.FcwHero__bottomContent
  display flex
  flex-direction column
  align-items center
  width 100%
  gap rem(24)

.FcwHero__subtitle
  max-width rem(360)

.FcwHero__ctaIcon
  transform rotate(90deg)
</style>

<script lang="ts" setup>
import type { FImageProps } from '@fifteen/design-system-vue';

type ImageSrc = Pick<FImageProps, 'src'>;

export interface FcwHeroProps {
  /**
   * Title of the hero
   */
  title: string;
  /**
   * Subtitle of the hero
   */
  subtitle?: string;
  /**
   * Images of the hero
   */
  images?: {
    lg: ImageSrc;
    md: ImageSrc;
    sm: ImageSrc;
    xs: ImageSrc;
  } | null;
  /**
   * Display mode of the hero.
   */
  mode?: 'default' | 'blog-home' | 'blog';
  /**
   * Gradient color from (left)
   */
  gradientFrom?: Color;
  /**
   * Gradient color to (right)
   */
  gradientTo?: Color;
  /**
   * Location of the CTA
   */
  ctaLocation?: string;
}

const props = withDefaults(defineProps<FcwHeroProps>(), {
  gradientFrom: 'primary--light-1',
  gradientTo: 'primary--dark-1',
  images: undefined,
  mode: 'default',
  ctaLocation: '',
  subtitle: '',
});

const classes = computed<VueClasses>(() => ({
  'FcwHero--blog': props.mode === 'blog',
  'FcwHero--blogHome': props.mode === 'blog-home',
  'FcwHero--backgroundImage': !!props.images,
}));

const style = computed<Style>(() => ({
  '--FcwHero--backgroundImageUrl--lg': `url(${props.images?.lg.src})`,
  '--FcwHero--backgroundImageUrl--md': `url(${props.images?.md.src})`,
  '--FcwHero--backgroundImageUrl--sm': `url(${props.images?.sm.src})`,
  '--FcwHero--backgroundImageUrl--xs': `url(${props.images?.xs.src})`,
  '--FcwHero--gradient': `linear-gradient(90deg, ${getCssColor(
    props.gradientFrom
  )} -20%, ${getCssColor(props.gradientTo)} 120%)`,
}));
</script>
